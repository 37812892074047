import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 420,
    minHeight: 240,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1)
  },
  title: {
    textAlign: 'start',
    overflowWrap: 'break-word',
    color: theme.palette.white,
    fontWeight: 'bold'
  },
  ul: {
    listStyle: 'none',
    paddingLeft: theme.spacing(2),
  },
  li: {
    color: theme.palette.white,
    '&::before': {
      content: '"-"',
      marginRight: theme.spacing(1)
    }
  },
}));

export default function CardComponent(props) {
  const {title, content, ...rest} = props;
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0} {...rest}>
      <CardContent>
        <Typography variant={"body2"} className={classes.title}>
          {title}
        </Typography>
        <ul className={classes.ul}>
          {content && content.split('\n').flatMap((cont, index) => {
            return (
              <Typography component={'li'} className={classes.li} variant={"subtitle1"} key={index}>
                  {cont}
              </Typography>
            )
          })}
        </ul>
      </CardContent>
    </Card>
  );
}
