import React from 'react';
import Box from '@material-ui/core/Box';
import ContentSection from "../components/ContentSection";
import SEO from "../components/utility/SEO";
import GridContainer from "../components/GridContainer";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardComponent from "../components/Card";
import IconDescription from "../components/IconDescription";
import {graphql} from "gatsby";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  text: {
    textAlign: 'center',
    maxWidth: 700,
  },
});

export default function PortfolioLaboratory({data}) {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!data || !data.prismicPortfolioLaboratoryPage) return '';
  const {data: pageData} = data.prismicPortfolioLaboratoryPage;

  function handleReverse(index) {
    return index % 2 === 0;
  }

  return (
      <>
        <SEO title={pageData.meta_title ? pageData.meta_title : "Portfolio laboratory"}/>
        <Box>
          <ContentSection
              imageBottom
              image={pageData.image ? pageData.image.url : null}
              title={pageData.title}
              subtitle={pageData.subtitle}
              style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}/>
          <GridContainer gridItemProps={{xs: 12, md: 12, style: !matches ? {padding: 8} : {padding: 16}}}
                         style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}>
            <Typography variant={"h4"}
                        className={classes.text}>{pageData.feature_cards_title}</Typography>
            <Typography variant={"subtitle1"} className={classes.text}>{pageData.feature_cards_subtitle}</Typography>
          </GridContainer>
          <GridContainer gridItemProps={{xs: 12, md: 3}}
                         style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}>
            {pageData.feature_cards && pageData.feature_cards.map((iconDescription, index) => {
              return (
                  <IconDescription icon={iconDescription.icon ? iconDescription.icon.url : null}
                                   description={iconDescription.description} key={index}/>
              )
            })}
          </GridContainer>
          <GridContainer gridItemProps={{xs: 12, md: 4, style: {padding: 8}}}
                         style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}>
            {pageData.info_cards && pageData.info_cards.map((card, index) => {
              return (
                  <CardComponent key={index} title={card.info_title} content={card.info_content}/>
              )
            })}
          </GridContainer>
          {pageData.body && pageData.body.map((content, index) => {
            if (!content.primary) return '';
            return (
                <ContentSection
                    reverse={handleReverse(index)}
                    key={index}
                    url={content.primary.url}
                    image={content.primary.image ? content.primary.image.url : null}
                    title={content.primary.title}
                    subtitle={content.primary.subtitle}
                    button={content.primary.button}
                    style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}
                />
            )
          })}
        </Box>
      </>
  );
}

export const portfolioLaboratoryPageQuery = graphql`
    query PortfolioLaboratoryPage($id: String!) {
        prismicPortfolioLaboratoryPage(id: {eq: $id}) {
            data {
                meta_title
                title
                subtitle
                image {
                    url
                }
                feature_cards_title
                feature_cards_subtitle
                feature_cards {
                    description
                    icon {
                        url
                    }
                }
                info_cards {
                    info_title
                    info_content
                }
                body {
                    ... on PrismicPortfolioLaboratoryPageBodyContentSection{
                        primary {
                            title
                            subtitle
                            button
                            url
                            image {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
