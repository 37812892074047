import React from 'react';
import {RichText} from 'prismic-reactjs';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import GatsbyLink from "./utility/GatsbyLink";
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    height: '100%',
    minHeight: 240
  },
  gridItem: {
    padding: theme.spacing(2),
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    maxWidth: theme.spacing(64)
  },
  box: {
    width: '100%',
    maxWidth: 316
  },
  title: {
    marginBottom: theme.spacing(2),
    maxWidth: 315,
  },
  subtitle: {
    marginBottom: theme.spacing(6),
    maxWidth: 315,
  },
  description: {
    marginBottom: theme.spacing(6),
    maxWidth: 315,
  },
  buttonLabel: {
    color: theme.palette.dark
  },
  centeredText: {
    textAlign: 'center'
  }
}));


export default function ContentSection(props) {
  const {title, subtitle, description, button, url, image, reverse = false, imageBottom = false, ...rest} = props;

  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction={reverse ? 'row' : 'row-reverse'} alignItems='stretch'
          justify="space-evenly" className={classes.root} {...rest}>
      <Hidden smDown initialWidth={'lg'}>
        <Grid
          item container justify={'center'}
          xs={12} md={6}
          className={classes.gridItem}>
            <img src={image} alt='' className={classes.image}/>
        </Grid>
      </Hidden>
      <Hidden smDown={imageBottom} mdUp initialWidth={'lg'}>
        <Grid
          item container justify={'center'}
          xs={12} md={6}
          className={classes.gridItem}>
          <img src={image} alt='' className={classes.image}/>
        </Grid>
      </Hidden>
      <Grid item xs={12} container md={6} direction='column' alignItems='center' justify="space-evenly"
            className={classes.gridItem}>
        <Box className={classes.box}>
          <Grid item container justify={!matches ? 'flex-start' : 'center'} className={matches ? classes.centeredText : null}>
            {title &&
            <Typography className={classes.title} variant={'h2'}>{title}</Typography>}
          </Grid>
          <Grid item container justify={!matches ? 'flex-start' : 'center'} className={matches ? classes.centeredText : null}>
            {subtitle &&
            <Typography className={classes.subtitle} variant={'subtitle1'}>{subtitle}</Typography>}
            {description && <Typography className={classes.description} variant={'subtitle1'}>
              <RichText render={description.raw}/>
            </Typography>}
          </Grid>
          <Grid item container justify={!matches ? 'flex-start' : 'center'}>
            {button &&
            <GatsbyLink url={url}>
              <Button
                classes={{label: classes.buttonLabel}}
                variant="outlined"
                size="small"
                color="primary">
                {button}
              </Button>
            </GatsbyLink>
            }
          </Grid>
        </Box>
      </Grid>
      <Hidden mdUp smDown={!imageBottom} initialWidth={'lg'}>
        <Grid
          item container justify={'center'}
          xs={12} md={6}
          className={classes.gridItem}>
          <img src={image} alt='' className={classes.image}/>
        </Grid>
      </Hidden>
    </Grid>
  );
}
